import { article, IArticle } from '@demo/services/article';
import createSliceState from './common/createSliceState';
import { Message } from '@arco-design/web-react';
import { history } from '@demo/utils/history';
import { emailToImage } from '@demo/utils/emailToImage';
import { IBlockData, BlockManager, BasicType } from 'easy-email-core';
import { IEmailTemplate } from 'easy-email-editor';
import { getTemplate } from '@demo/config/getTemplate';
import { common } from '@demo/services/common';

export function getAdaptor(data: IArticle): IEmailTemplate {
  const content = JSON.parse(data.content.content) as IBlockData;
  // console.log(data,content);
  return {
    ...data,
    content,
    subject: data.title,
    subTitle: data.summary,
    purposeLabel: data.purposeLabel,
    stage: data.stage,
    mailSmsSendType: data.mailSmsSendType,
  };
}

export default createSliceState({
  name: 'template',
  initialState: null as IEmailTemplate | null,
  reducers: {
    set: (state, action) => {
      return action.payload;
    },
  },
  effects: {
    fetchById: async (
      state,
      {
        id,
        userId,
      }: {
        id: number;
        userId: number;
      }
    ) => {
      try {
        let data = await getTemplate(id);
        if (!data) {
          data = await article.getArticle(id, userId);
        }
        return getAdaptor(data);
      } catch (error) {
        history.replace('/');
        throw error;
      }
    },
    fetchDefaultTemplate: async (state, data) => {
      let templatePurpose;
      let fromCrm;
      let instituteId;
      let templateType;
      let editorId;
      console.log(data);
      if (data) {
        // templatePurpose = data.templatePurpose;
        // instituteId = data.instituteId;
        // templateType = data.templateType;
        // fromCrm = data.fromCrm;
        editorId = +data.editorId;
      }
      // without template purpose open an empty template

      try {
        let resData = await common.fetchTemplate({ editorId });
        console.log(resData);
        // let template = resData[0];
        //if json field is null but template purpose is available open an empty template
        if (!resData.jsonField) {
          return {
            subject: 'Libsys Template Editor',
            subTitle: 'From Libsys.ltd',
            content: BlockManager.getBlockByType(BasicType.PAGE).create({}),
          } as IEmailTemplate;
        }
        let updateStruct = { title: resData.jsonField ?.subject || data.subject || '', summary: resData.jsonField ?.summary || '', content: { content: resData.jsonField ?.content}, picture: resData.jsonField ?.picture, article_id: 0, };
        console.log(updateStruct);
        console.log(getAdaptor(updateStruct));
        return getAdaptor(updateStruct);
      } catch (error) {
        alert('please try again , not able to find your template');
      }



    },
    create: async (
      state,
      payload: {
        editorId: number,
        automatic: boolean,
        purposeLabel: string,
        template: IEmailTemplate,
        message: any
        success: (id: number, data: IEmailTemplate, purposeLabel: number) => void;
      }
    ) => {
      try {
        console.log(payload.template.content);
        const picture = await emailToImage(payload.template.content, payload.editorId);
        const data = await article.addArticle({
          picture,
          editorId: payload.editorId,
          automaticMail:payload.automatic,
          summary: payload.template.subTitle,
          title: payload.template.subject,
          content: JSON.stringify(payload.template.content),
          message: payload.message,
        });
        console.log(data['data']['1'][0].jsonField);
        let updateStruct = { title: data['data']['1'][0].jsonField ?.subject || '', summary: data['data']['1'][0].jsonField ?.summary || '', content: { content: data['data']['1'][0].jsonField ?.content}, picture: data['data']['1'][0].jsonField ?.picture, article_id: 0, };
        payload.success(data.article_id, getAdaptor(updateStruct), data['data']['1'][0].templatePurpose);
        return { ...data['data']['1'][0], ...payload.template };
      } catch (error) {
        alert('please try again');
        return;
      }

      // return getAdaptor(updateStruct);
    },
    duplicate: async (
      state,
      payload: {
        article: {
          article_id: number;
          user_id: number;
        };
        success: (id: number) => void;
      }
    ) => {
      const source = await article.getArticle(
        payload.article.article_id,
        payload.article.user_id
      );
      const data = await article.addArticle({
        title: source.title,
        content: source.content.content,
        picture: source.picture,
        summary: source.summary,
      });
      payload.success(data.article_id);
    },
    updateById: async (
      state,
      payload: {
        id: number;
        template: IEmailTemplate;
        success: (templateId: number) => void;
      }
    ) => {
      try {
        let isDefaultTemplate = await getTemplate(payload.id);
        if (isDefaultTemplate) {
          Message.error('Cannot change the default template');
          return;
        }

        const picture = await emailToImage(payload.template.content, 0);
        await article.updateArticle(payload.id, {
          picture,
          content: JSON.stringify(payload.template.content),
          title: payload.template.subject,
          summary: payload.template.subTitle,
        });
        payload.success(payload.id);
      } catch (error: any) {
        if (error ?.response ?.status === 404) {
          throw {
            message: 'Cannot change the default template',
          };
        }
      }
    },
    removeById: async (state, payload: { id: number; success: () => void; }) => {
      try {
        await article.deleteArticle(payload.id);
        payload.success();
        Message.success('Removed success.');
      } catch (error: any) {
        if (error ?.response ?.status === 404) {
          throw {
            message: 'Cannot delete the default template',
          };
        }
      }
    },
  },
});
