import { common } from '@demo/services/common';
import { type } from 'os';
import createSliceState from './common/createSliceState';

type keyword=Array<{key:string,value:string}>;

export default createSliceState({
  name:'keywords',
  initialState:[] as keyword,
  reducers:{
    set:(state,action)=>{
      return action.payload;
    },
  },
  effects:{
    fetchKeywords:async(state,{instituteId})=>{
      try {
        let keywords:keyword= await common.fetchKeywords(instituteId);
        console.log(keywords);
        return  keywords;
      } catch (error) {
        console.log(error);
        throw error;
      }
    }
  }
});