import { request } from './axios.config';
import { getCookie } from '../utils/utils';
import { v4 as uuidv4 } from 'uuid';
const QI_NIUI_KEY = 'qiniuConfig';

type QiniuConfig = { origin: string; token: string; };
type smaMailTemplateModel = { editorId: number };

const serviceUrl: string | undefined = import.meta.env.VITE_TARGETX_SERVICE_URL;

export const common = {

  async uploadByQiniu(file: File | Blob, editorId?): Promise<string> {
    const qiniuCookie = getCookie(QI_NIUI_KEY); // 有cookie先拿cookie
    let qiniuConfig: QiniuConfig;
    // if (qiniuCookie) {
    //   qiniuConfig = JSON.parse(qiniuCookie);
    // } else {
    //   qiniuConfig = await request.get<QiniuConfig>(
    //     '/upload/visitor/qiniu-token'
    //   );
    //   document.cookie = `${QI_NIUI_KEY}=${JSON.stringify(
    //     qiniuConfig
    //   )}; max-age=540;`; // 设置十分钟有效期
    // }
    // const { token, origin } = qiniuConfig;
    const data = new FormData();
    data.append('instituteImage', file);
    if (file['name']) {
      let name = file['name'].split(' ').join('');
      data.append('emailDocsUpload', name);
    } else {
      data.append('emailDocsUpload', uuidv4() + '.png');
    }

    data.append('editorId', editorId);
    // data.append('token', token);
    // data.append('key', uuidv4() + `-${(file as any) ?.name || ''}`);
    const res = await request.post<{ data: string; }>(
      serviceUrl + '/upload',
      data
    );
    // return origin + '/' + res.key;
    return res.data;
  },

  async fetchTemplateKeywordApi(params) {
    console.log(params);
    const res: Object = await request.get(serviceUrl + '/fetchBy/editorId', { params: params });
    return res;
  },

  async fetchKeywords(editorId: number) {
    // console.log(instituteId, typeof (instituteId));
    let inputObject: smaMailTemplateModel;
    // inputObject = { instituteId, fromCrm: true, templateTypes: [1], campusId: 0 };
    // let queryParams = { instituteId, getKeywords: true, fetchDefault: false };
    inputObject = { editorId };
    let res = await this.fetchTemplateKeywordApi(inputObject);
    console.log(res);
    let keywordArray: Array<{ key: string, value: string; }> = [];
    for (let keyword in res['otherInfo']['keywords']) {
      keywordArray.push({ value: keyword, key: res['otherInfo']['keywords'][keyword] });
    }
    return keywordArray;
  },

  async fetchTemplate({ editorId }) {
    console.log(editorId);

    // let queryParams = { instituteId, getKeywords: false, fetchDefault: false };
    let inputObject: smaMailTemplateModel;
    // inputObject = { instituteId, fromCrm: fromCrm === 'true' ? true : false, templateType: +templateType, campusId: 0, templatePurpose, templateTypes: [+templateType] };
    // console.log(inputObject, queryParams);
    inputObject = { editorId: editorId };
    let res = await this.fetchTemplateKeywordApi(inputObject);
    return res['data'];
  },

  uploadByUrl(url: string) {
    return request.get<string>('/upload/user/upload-by-url', {
      params: {
        url,
      },
    });
  },
  getMenu(): Promise<IAppMenuItem[]> {
    return Promise.resolve([
      {
        name: '数据模板',
        icon: 'bar-chart',
        isOpen: true,
        children: [
          {
            name: '数据模板',
            url: '/',
          },
        ],
      },
    ]);
  },
  sendTestEmail(data: {
    toEmail: string;
    subject: string;
    html: string;
    text: string;
  }) {
    return request.post('/email/user/send', {
      to_email: data.toEmail,
      subject: data.subject,
      text: data.text,
      html: data.html,
    });
  },
};

export interface IAppMenuItem {
  name: string;
  url?: string;
  icon: string;
  isOpen?: boolean;
  children: IAppSubMenuItem[];
}

export interface IAppSubMenuItem {
  name: string;
  url: string;
  isOpen?: boolean;
}
