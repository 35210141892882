import { getUserConfig } from './getUserConfig';

export const ASSET_DOMAIN = 'https://assets.maocanhua.cn';

export const USER = getUserConfig({
  // your account
  phone: '1225',
  password: '1225',
  categoryId: 96,

  // standard user
  provideUserId: 77,
  provideCategoryId: 90
});


export const formStage=[{value:0,label:'Total Leads'},{value:1,label:'New Leads'},{value:4,label:'Contacted'},{value:6,label:'Form Started'},{value:7,label:'Form Submitted'},{value:8,label:'Seat Alloted'},{value:9,label:'Enrolled'}];

